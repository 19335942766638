import Vue from 'vue';
import Component from 'vue-class-component';
import {Inject, Ref} from 'vue-property-decorator';
import {DocumentCreationService, Template} from '@bakerware/document-creation-admin';
import {required} from '@bakerware/vue-validation-rules';
import {VForm} from '@bakerware/vue-dialogs';
import {ActionTypeTemplate, ActionTypeTemplateRequest, TaxOffice} from "@ncnp-mono/generics";
import {ActionTypeService, TaxOfficeService} from "@ncnp-mono/utils";
import html from './edit-action.component.html';

@Component({
    template: html,
})

export class EditActionComponent extends Vue {
    @Inject()
    actionTypeService!: ActionTypeService;

    @Inject()
    taxOfficeService!: TaxOfficeService;

    @Inject()
    documentCreationService!: DocumentCreationService;

    @Ref()
    form!: VForm;

    public actionTypeTemplate: ActionTypeTemplate | null = null;
    public templates: Template[] = [];

    public taxOffice: TaxOffice | null = null;

    public actionTypeTemplateRequest: ActionTypeTemplateRequest = {
        taxOfficeId: parseInt(this.$route.params.taxOfficeId)
    };

    public rules = {
        templateId: [required],
    };

    public formValid = false;
    public loading = true;
    public saving = false;

    public async beforeMount(): Promise<void> {
        await this.getTemplates();
        await this.getTaxOffice();

        if (this.taxOffice) {
            await this.getActionTypeTemplate(this.taxOffice);
        }

        this.loading = false;
    }

    private async getTaxOffice(): Promise<void> {
        try {
            this.taxOffice = await this.taxOfficeService.getTaxOffice(parseInt(this.$route.params.taxOfficeId))
        } catch {
            this.$snackbar.danger('Het ophalen van het belastingkantoor is mislukt');
        }
    }

    private async getActionTypeTemplate(taxOffice: TaxOffice): Promise<void> {
        try {
            this.actionTypeTemplate = await this.actionTypeService.getActionTypeTemplateByTaxOffice(
                parseInt(this.$route.params.actionType),
                taxOffice.id
            );

            this.actionTypeTemplateRequest.templateId = this.actionTypeTemplate.templateId;
        } catch {
            this.$snackbar.danger('Het ophalen van de action type template is mislukt');
        }
    }

    private async getTemplates(): Promise<void> {
        try {
            this.templates = await this.documentCreationService.getTemplates();
        } catch {
            this.$snackbar.danger('Het ophalen van de templates uit DocumentCreatie is mislukt')
        }
    }

    public async save(): Promise<void> {
        if (!this.formValid) {
            return;
        }

        this.saving = true;

        if (this.actionTypeTemplate) {
            await this.updateActionTypeTemplate(this.actionTypeTemplate.id);
        } else {
            await this.createActionTypeTemplate();
        }

        this.saving = false;
    }

    private async createActionTypeTemplate(): Promise<void> {
        try {
            this.actionTypeTemplate = await this.actionTypeService.createActionTypeTemplate(
                parseInt(this.$route.params.actionType),
                this.actionTypeTemplateRequest
            );

            this.$snackbar.success('Template is succesvol gekoppeld');
        } catch {
            this.$snackbar.danger('De template kan niet worden gekoppeld');
        }
    }

    private async updateActionTypeTemplate(actionTypeTemplateId: number): Promise<void> {
        try {
            this.actionTypeTemplate = await this.actionTypeService.updateActionTypeTemplate(
                actionTypeTemplateId,
                this.actionTypeTemplateRequest
            );

            this.$snackbar.success('De template is opgeslagen');
        } catch {
            this.$snackbar.danger('De template kan niet worden opgeslagen');
        }
    }
}
